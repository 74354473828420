import { Container, Row, Col, Button, Accordion } from "react-bootstrap";
import { useState } from "react";
const AdminMenu = () => {
  return (
    <div>
      <h2>Admin Menu</h2>
    </div>
  );
};
export default AdminMenu;
